import { Button, CardActions, CardContent, Modal, Typography, CircularProgress, TextField } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { ConfirmCard } from '../Layout/styles';
import { useEffect, useState } from 'react';

interface FordaModalProps {
    open: boolean;
    onClose: () => void;
    isSelectedForda: boolean;
    saveFordaLoading: boolean;
    initFordaName: string;
    onSave: (fordaName: string) => void;
}

export default function FordaModal({ open, onClose, isSelectedForda, saveFordaLoading, initFordaName, onSave }: FordaModalProps) {
    const [fordaName, setFordaName] = useState<string>(initFordaName);

    useEffect(() => {
        setFordaName(initFordaName);
    }, [initFordaName]);

    return (
        <Modal open={open} onClose={onClose}>
            <ConfirmCard>
                <CardContent sx={{ p: 2 }}>
                    <Typography variant={'h6'} pb={3}>
                        {isSelectedForda ? 'Forda szerkesztése' : 'Forda létrehozása'}
                    </Typography>
                    <TextField label='Forda név' fullWidth value={fordaName} onChange={e => setFordaName(e.target.value)} variant='outlined' />
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between' }}>
                    <Button
                        sx={{ mx: 1, borderRadius: 50 }}
                        variant={'contained'}
                        size={'small'}
                        color={'info'}
                        onClick={onClose}
                        startIcon={<ArrowBackIosNewTwoToneIcon />}>
                        Mégsem
                    </Button>
                    <Button
                        variant='contained'
                        size={'small'}
                        sx={{ borderRadius: 50, marginRight: 0.25 }}
                        onClick={() => onSave(fordaName)}
                        endIcon={saveFordaLoading ? <CircularProgress size={20} color='inherit' /> : <SaveAsIcon />}
                        disabled={saveFordaLoading}>
                        {saveFordaLoading ? 'Mentés...' : isSelectedForda ? 'Mentés' : 'Létrehozás'}
                    </Button>
                </CardActions>
            </ConfirmCard>
        </Modal>
    );
}
