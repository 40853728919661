import {
    GridFilterItem,
    gridFilterModelSelector,
    GridFilterOperator,
    GridHeaderFilterCellProps,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid-pro';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useCallback } from 'react';
import { ValidityType } from '../../@types/validity';

const validityToFilterOperatorsData = [
    {
        type: ValidityType.FROM,
        value: 'validityFromAfterDateRange',
        label: 'Ezutáni vagy ezen a napon',
        comparisonFn: (value: Date, selectedDate: Date) => value >= selectedDate,
        allowEmpty: false,
    },
    {
        type: ValidityType.FROM,
        value: 'validityFromBeforeDateRange',
        label: 'Ezelőtti vagy ezen a napon',
        comparisonFn: (value: Date, selectedDate: Date) => value <= selectedDate,
        allowEmpty: false,
    },
    {
        type: ValidityType.TO,
        value: 'validityToAfterDateRange',
        label: 'Ezutáni vagy ezen a napon',
        comparisonFn: (value: Date, selectedDate: Date) => value >= selectedDate,
        allowEmpty: true,
    },
    {
        type: ValidityType.TO,
        value: 'validityToBeforeDateRange',
        label: 'Ezelőtti vagy ezen a napon',
        comparisonFn: (value: Date, selectedDate: Date) => value <= selectedDate,
        allowEmpty: false,
    },
];

interface ValidityOperatorInterface {
    [key: string]: GridFilterOperator[];
}

export const ValidityOperatorFilters: ValidityOperatorInterface = {};
Object.values(ValidityType).forEach(type => {
    ValidityOperatorFilters[type] = [];
});

validityToFilterOperatorsData.forEach(({ type, value, label, comparisonFn, allowEmpty }) => {
    ValidityOperatorFilters[type].push({
        value: value,
        label: label,
        headerLabel: label,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            const selectedDate = new Date(filterItem.value);
            return rawValue => {
                if (rawValue === 'nincs megadva') {
                    return true;
                }
                if (!rawValue) {
                    return allowEmpty;
                }
                const value = new Date(rawValue);
                return comparisonFn(value, selectedDate);
            };
        },
        InputComponent: DateRangeHeaderFilter,
    });
});

function DateRangeHeaderFilter(
    props: GridHeaderFilterCellProps & {
        clearButton: React.ReactNode;
    }
) {
    const { item, clearButton } = props;
    const apiRef = useGridApiContext();
    const filterModel = useGridSelector(apiRef, gridFilterModelSelector);

    const applyFilterChange = useCallback(
        (updateItem: GridFilterItem) => {
            if (item.value && !updateItem.value) {
                apiRef.current.deleteFilterItem(item);
                return;
            }
            apiRef.current.upsertFilterItem(updateItem);
        },
        [apiRef, item]
    );

    return (
        <>
            <DatePicker
                label='Dátum'
                value={filterModel.items.find(filterItem => filterItem.field === item.field)?.value || null}
                onChange={date => {
                    applyFilterChange({
                        ...item,
                        value: date,
                    });
                }}
                slotProps={{
                    textField: {
                        variant: 'standard',
                        sx: {
                            width: '180px',
                        },
                        error: false,
                    },
                }}
            />
            {clearButton}
        </>
    );
}
