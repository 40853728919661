import React, { forwardRef } from 'react';
import { Grid } from '@mui/material';
import { Forda, Line } from '../../@types/line';

type DndItemProps = {
    item?: Forda | Line;
};

type Props = React.HTMLProps<HTMLDivElement>;

const DndItem = forwardRef<HTMLDivElement | null, Props & DndItemProps>((props: any, ref) => {
    return (
        <Grid {...props} ref={ref}>
            {props.children || <Grid>{props.id}</Grid>}
        </Grid>
    );
});

export { DndItem };
