import { Line, Forda } from '../../@types/line';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DndItem } from './DndItem';
import { DragIndicator } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import { useRouteListSelectionContext } from '../../hooks/useRouteListSelectionContext';

type DndItemContextProps = {
    type: string;
    item: Forda | Line;
};

export default function DndItemContext({ type, item }: DndItemContextProps) {
    const { lineSelectionStatus, setLineSelectionStatus } = useRouteListSelectionContext();
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: item.ID,
        data: {
            type: type,
            item: item,
        },
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <>
            {type === 'line' ? (
                <DndItem ref={setNodeRef} {...attributes} style={style}>
                    <DragIndicator
                        {...listeners}
                        sx={{
                            verticalAlign: 'middle',
                            cursor: 'grab',
                            transition,
                            transform,
                        }}
                    />
                    <Checkbox
                        key={item.ID}
                        sx={{ paddingLeft: 0 }}
                        checked={lineSelectionStatus[item.ID] || false}
                        onChange={event => {
                            setLineSelectionStatus({
                                ...lineSelectionStatus,
                                [item.ID]: event.target.checked,
                            });
                        }}
                    />
                </DndItem>
            ) : (
                <DndItem
                    ref={setNodeRef}
                    {...attributes}
                    style={{
                        ...style,
                        width: '100%',
                        height: '100%',
                    }}
                />
            )}
        </>
    );
}
